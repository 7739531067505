/* eslint-disable import/extensions */
// This file is managed manually for now but we can extend it later. For now, we
// need to import each language's namespaces here and then export them as their
// own language. See 'en' example

import common from './en/common.json';
import dashboard from './en/dashboard.json';
import dataTable from './en/dataTable.json';
import account from './en/account.json';
import activation from './en/activation.json';
import audiences from './en/audiences.json';
import billing from './en/billing.json';
import notifications from './en/notifications.json';
import leads from './en/leads.json';
import login from './en/login.json';
import businessObjects from './en/businessObjects.json';
import businessObject from './en/businessObject.json';
import chrome from './en/chrome.json';
import errorPage from './en/errorPage.json';
import programCreate from './en/programCreate.json';
import automatedPrograms from './en/automatedPrograms.json';
import automatedDetails from './en/automatedDetails.json';
import partner from './en/partner.json';
import programs from './en/programs.json';
import programPerf from './en/programPerf.json';
import gallery from './en/gallery.json';
import blueprintSelector from './en/blueprintSelector.json';
import businessObjectSelector from './en/businessObjectSelector.json';
import adPreview from './en/adPreview.json';
import programPreviewDrawer from './en/programPreviewDrawer.json';
import automatedProgramFilter from './en/automatedProgramFilter.json';
import renderTemplateStringTextField from './en/renderTemplateStringTextField.json';
import program from './en/program.json';
import officeSelector from './en/officeSelector.json';
import facebookSettings from './en/facebookSettings.json';
import facebookPages from './en/facebookPages.json';
import notificationCenter from './en/notificationCenter.json';
import admin from './en/admin.json';
import adminPromotions from './en/adminPromotions.json';
import adminGalleries from './en/adminGalleries.json';
import renderBusinessObjectAssetSelector from './en/renderBusinessObjectAssetSelector.json';
import blueprintBuilder from './en/blueprintBuilder.json';
import adminSkinSettings from './en/adminSkinSettings.json';
import adminResourceLibrary from './en/adminResourceLibrary.json';
import adminGoogleSearch from './en/adminGoogleSearch.json';
import adminContentDataManager from './en/adminContentDataManager.json';
import aiSuggestion from './en/aiSuggestion.json';
import orgContentSelector from './en/orgContentSelector.json';
import emojiSelector from './en/emojiSelector.json';
import teams from './en/teams.json';
import programsByArchitecture from './en/programsByArchitecture.json';
import automations from './en/automations.json';
import validations from './en/validations.json';
import programStepFooter from './en/programStepFooter.json';
import paymentErrors from './en/paymentErrors.json';
import programEdit from './en/programEdit.json';
import audienceTools from './en/audienceTools.json';
import ConfirmationModalFooter from './en/ConfirmationModalFooter.json';
import DeleteConfirmationModal from './en/DeleteConfirmationModal.json';
import adminChannelSpendAllocation from './en/adminChannelSpendAllocation.json';
import adminChannelSpendAllocationConfigUpdater from './en/adminChannelSpendAllocationConfigUpdater.json';
import automatedProgramCreate from './en/automatedProgramCreate.json';
import content from './en/content.json';
import copyToClipboard from './en/copyToClipboard.json';
import createFacebookAudienceAccountCreationRuleForm from './en/createFacebookAudienceAccountCreationRuleForm.json';
import facebookAccountCreationRulesForm from './en/facebookAccountCreationRulesForm.json';
import lead from './en/lead.json';
import experimentSettings from './en/experimentSettings.json';
import adminExperimentSettings from './en/adminExperimentSettings.json';
import editableSummaryItem from './en/editableSummaryItem.json';
import contentAwareDashboard from './en/contentAwareDashboard.json';
import quickAutomation from './en/quickAutomation.json';
import localization from './en/localization.json';
import notificationsForm from './en/notificationsForm.json';
import adminDashboard from './en/adminDashboard.json';
import kpiTile from './en/kpiTile.json';
import multiInput from './en/multiInput.json';

const en = {
  common,
  dashboard,
  dataTable,
  account,
  activation,
  audiences,
  billing,
  notifications,
  leads,
  login,
  businessObjects,
  businessObject,
  chrome,
  errorPage,
  programCreate,
  automatedPrograms,
  automatedDetails,
  programs,
  programPerf,
  gallery,
  blueprintSelector,
  businessObjectSelector,
  adPreview,
  programPreviewDrawer,
  automatedProgramFilter,
  renderTemplateStringTextField,
  partner,
  program,
  officeSelector,
  facebookSettings,
  facebookPages,
  notificationCenter,
  admin,
  adminPromotions,
  adminGalleries,
  renderBusinessObjectAssetSelector,
  blueprintBuilder,
  adminSkinSettings,
  adminResourceLibrary,
  adminGoogleSearch,
  adminContentDataManager,
  aiSuggestion,
  orgContentSelector,
  emojiSelector,
  teams,
  programsByArchitecture,
  automations,
  validations,
  programStepFooter,
  paymentErrors,
  programEdit,
  audienceTools,
  ConfirmationModalFooter,
  DeleteConfirmationModal,
  adminChannelSpendAllocation,
  adminChannelSpendAllocationConfigUpdater,
  automatedProgramCreate,
  content,
  copyToClipboard,
  createFacebookAudienceAccountCreationRuleForm,
  facebookAccountCreationRulesForm,
  lead,
  adminExperimentSettings,
  experimentSettings,
  editableSummaryItem,
  contentAwareDashboard,
  quickAutomation,
  localization,
  notificationsForm,
  adminDashboard,
  kpiTile,
  multiInput
};

export { en };
